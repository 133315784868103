<template>
  <b-card>
    <b-form
      @submit.prevent
      @reset.prevent="resetForm"
    >
      <validation-observer ref="form">
        <b-row>
          <b-col
            md="3"
            lg="3"
          >
            <b-form-group
              label="Documento"
              label-for="tipoDoc"
            >
              <validation-provider
                #default="{ errors }"
                name="Documento"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="tipoDoc"
                    v-model="documentosContrato.descricao"
                    type="text"
                    maxlength="15"
                  />
                  <b-input-group-prepend>
                    <b-button
                      variant="outline-secondary"
                      @click="showModalDocumentosContrato"
                    >
                      <feather-icon icon="SearchIcon" />
                    </b-button>
                  </b-input-group-prepend>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
              label="Série"
              label-for="h-serie"
            >
              <validation-provider
                #default="{ errors }"
                name="Número"
                rules="required"
              >
                <v-select
                  v-model="serieSelecionada.serie"
                  :options="seriesContrato.series"
                  label="serie"
                  title="serie"
                  :reduce="serieSelecionada => serieSelecionada"
                  required
                  @input="alterarNumero(serieSelecionada.serie)"
                >
                  <template #option="option">
                    <span>{{ option.serie }} - {{ option.descricao }}</span>
                  </template>
                  <small class="text-danger">{{ errors[0] }}</small>
                </v-select></validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="3"
          >
            <b-form-group
              label="Número"
              label-for="h-numero"
              class="align-text-right"
            >

              <validation-provider
                #default="{ errors }"
                name="Número"
                rules="required"
              >
                <b-input-group>
                  <b-input-group-prepend>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      class="btn-icon"
                      variant="outline-secondary"
                      @click="subtraiNumerador(contrato.numero), getOneContrato()"
                    >
                      <feather-icon icon="ChevronLeftIcon" />
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input
                    id="numero"
                    v-model="contrato.numero"
                    class="textoCentrado"
                    type="number"
                    maxlength="15"
                  />
                  <b-input-group-prepend>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      class="btn-icon"
                      variant="outline-secondary"
                      @click="somaNumerador(contrato.numero), getOneContrato()"
                    >

                      <feather-icon icon="ChevronRightIcon" />
                    </b-button>
                    <b-button
                      variant="outline-secondary"
                      @click="showModalContratos"
                    >
                      <feather-icon icon="SearchIcon" />
                    </b-button>
                  </b-input-group-prepend>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Referência"
              label-for="h-referencia"
            >
              <div>
                <h4>
                  <span v-if="documentosContrato.codigo !='' && contrato.numero != '' && serieSelecionada.serie != ''">{{ documentosContrato.codigo }}/{{ serieSelecionada.serie.serie }}/{{ contrato.numero }}</span>
                  <span v-else>#</span>

                </h4>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="3"
            lg="3"
          >
            <b-form-group
              label="Entidade"
              label-for="tipoDoc"
            >
              <validation-provider
                #default="{ errors }"
                name="Entidade"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="entidade"
                    v-model="cliente.cliente"
                    type="text"
                    maxlength="15"
                  />
                  <b-input-group-prepend>
                    <b-button
                      variant="outline-secondary"
                      @click="showModalClientes"
                    >
                      <feather-icon icon="SearchIcon" />
                    </b-button>
                  </b-input-group-prepend>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="9">
            <b-form-group
              label="Nome"
              label-for="h-nome"
            >
              <div>
                <h4>
                  <h4>
                    <span v-if="cliente.nome != ''">{{ cliente.nome }}</span>
                    <span v-else>Selecionar Cliente</span>

                  </h4>

                </h4>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group
              label="Descrição"
              label-for="h-descricao"
            >
              <validation-provider
                #default="{ errors }"
                name="Descrição"
                rules="required"
              >
                <b-form-input
                  id="h-descricao"
                  v-model="contrato.descricao"
                  placeholder="Descrição"
                  maxlength="250"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </b-form>

    <b-modal
      ref="modalDocumentosContrato"
      hide-footer
      size="xl"
    >
      <selecionarDocumentoContrato @documentosContratoSelecionado="selectDocumentosContrato" />
    </b-modal>
    <b-modal
      ref="modalClientes"
      hide-footer
      size="xl"
    >
      <selecionarCliente @clienteSelecionado="selectCliente" />
    </b-modal>

    <b-modal
      ref="modalContratos"
      hide-footer
      size="xl"
    >
      <selecionarContrato @contratoSelecionado="selectContratos" />
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BInputGroupPrepend, BInputGroup, BModal,
} from 'bootstrap-vue'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import pt from 'vee-validate/dist/locale/pt_PT'
import vSelect from 'vue-select'
import router from '@/router'
import selecionarCliente from '../../Listagens/Clientes/SelecionarCliente.vue'
import selecionarDocumentoContrato from '../../Listagens/DocumentosContratos/SelecionarDocumentoContratos.vue'
import selecionarContrato from '../../Listagens/Contratos/SelecionarContrato.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BInputGroupPrepend,
    BInputGroup,
    BModal,
    vSelect,
    selecionarCliente,
    selecionarDocumentoContrato,
    selecionarContrato,
  },

  directives: {
    Ripple,
  },
  props: {
    cliente: Object,
    contrato: Object,
    documentosContrato: Object,
    seriesContrato: Object,
    serieSelecionada: Object,
    estados: Object,
    estadoSelecionado: Object,
    vendedor: Object,
    moeda: Object,
    valorDisponivel: Object,
  },

  data() {
    return {
      required,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    localize('pt', pt)
    if (router.currentRoute.params.id !== undefined) {
      this.getOneContratoId()
    }
  },

  methods: {
    getOneContratoId() {
      const contratoId = this.$route.params.id // Obtenha o valor do parâmetro "id" da rota atual
      const apiUrl = `/api/v1/contratos/${contratoId}/complete`

      axios.get(apiUrl)
        .then(res => { this.selectContratos(res.data) })
        .catch(error => {
          // Lide com erros aqui
        })
      this.$forceUpdate()
    },
    selectCliente(row) {
      this.cliente.cliente = row.cliente
      this.cliente.id = row.id
      this.cliente.nome = row.nome
      this.cliente.morada = row.morada
      this.cliente.codigoPostal = row.codigoPostal
      this.cliente.nif = row.nif
      this.cliente.idPais = row.idPais
      this.cliente.idMoeda = row.idMoeda
      this.cliente.idZona = row.idZona
      this.cliente.idVendedor = row.idVendedor
      this.cliente.telefone = row.telefone
      this.cliente.telemovel = row.telemovel
      this.cliente.email = row.email
      this.cliente.nomeFiscal = row.nomeFiscal
      this.cliente.localidade = row.localidade
      this.cliente.emailServicos = row.emailServicos
      this.cliente.ativo = row.ativo
      this.cliente.bloqueado = row.bloqueado
      this.cliente.observacoes = row.observacoes
      this.contrato.entidade = row.id
      this.hideModalClientes()
    },
    showModalClientes() {
      this.$refs.modalClientes.show()
    },
    hideModalClientes() {
      this.$refs.modalClientes.hide()
    },

    subtraiNumerador(numero) {
      this.contrato.numero = numero - 1
    },
    somaNumerador(numero) {
      this.contrato.numero = numero + 1
    },
    selectDocumentosContrato(row) {
      this.serieSelecionada.serie = []
      this.seriesContrato.series = []
      this.documentosContrato.id = row.id
      this.contrato.tipoDoc = row.id
      this.documentosContrato.codigo = row.codigo
      this.documentosContrato.descricao = row.descricao
      this.documentosContrato.inactivo = row.inactivo
      axios.get(`/api/v1/seriesContrato/documentosContrato/${this.documentosContrato.id}/complete/ativo`)
        .then(res => {
          const resultado = res.data

          this.seriesContrato.series.length = 0
          for (let i = 0; i < resultado.length; i += 1) {
            this.seriesContrato.series.push(JSON.parse(JSON.stringify(resultado[i])))
            if (this.seriesContrato.series[i].porDefeito === 1) {
              this.serieSelecionada.serie = this.seriesContrato.series[i]
              this.contrato.serie = this.seriesContrato.series[i].id
            }
          }
          this.alterarNumero()
        })
      this.hideModalDocumentosContrato()
      this.$forceUpdate()
    },
    selectContratos(row) {
      this.serieSelecionada.serie = []
      this.seriesContrato.series = []
      this.contrato.id = row.id
      this.contrato.tipoDoc = row.tipoDoc
      this.contrato.serie = row.serie
      this.contrato.numero = row.numero
      this.contrato.codigo = row.codigo
      this.contrato.data = row.data
      this.contrato.entidade = row.entidade
      this.contrato.referencia = row.referencia
      this.contrato.estado = row.estado

      if (row.horasValidade === 1) {
        this.contrato.horasValidade = true
      } else if (this.horasValidade === 0) {
        this.contrato.horasValidade = false
      }
      if (row.periodoValidade === 1) {
        this.contrato.periodoValidade = true
      } else if (row.periodoValidade === 0) {
        this.contrato.periodoValidade = false
      }
      if (row.incidenciasValidade === 1) {
        this.contrato.incidenciasValidade = true
      } else if (row.incidenciasValidade === 0) {
        this.contrato.incidenciasValidade = false
      }
      if (row.excHorasValidade === 1) {
        this.contrato.excHorasValidade = true
      } else if (row.excHorasValidade === 0) {
        this.contrato.excHorasValidade = false
      }
      if (row.excPeriodoValidade === 1) {
        this.contrato.excPeriodoValidade = true
      } else if (row.excPeriodoValidade === 0) {
        this.contrato.excPeriodoValidade = false
      }
      if (row.excIncidenciasValidade === 1) {
        this.contrato.excIncidenciasValidade = true
      } else if (row.excIncidenciasValidade === 0) {
        this.contrato.excIncidenciasValidade = false
      }
      if (row.renovavel === 1) {
        this.contrato.renovavel = true
      } else if (row.renovavel === 0) {
        this.contrato.renovavel = false
      }
      if (row.cancelado === 1) {
        this.contrato.cancelado = true
      } else if (row.cancelado === 0) {
        this.contrato.cancelado = false
      }
      if (row.processaFaturacao === 1) {
        this.contrato.processaFaturacao = true
      } else if (row.processaFaturacao === 0) {
        this.contrato.processaFaturacao = false
      }
      this.contrato.canceladoMotivo = row.canceladoMotivo
      this.contrato.periodoInicio = row.periodoInicio
      this.contrato.periodoFim = row.periodoFim
      this.contrato.moeda = row.moeda
      this.contrato.valorContrato = row.valorContrato
      this.contrato.utilizador = row.utilizador
      this.contrato.vendedor = row.vendedor
      this.contrato.precoBase = row.precoBase
      this.contrato.descricao = row.descricao
      this.contrato.dataAdjudicacao = row.dataAdjudicacao

      this.contrato.horasTotais = parseFloat(row.horasTotais).toFixed(2)
      this.contrato.horasGastas = parseFloat(row.horasGastas).toFixed(2)
      this.contrato.incidenciasTotais = row.incidenciasTotais
      this.contrato.incidenciasGastas = row.incidenciasGastas
      this.contrato.excPeriodoInicio = row.excPeriodoInicio
      this.contrato.excPeriodoFim = row.excPeriodoFim

      this.contrato.excHorasTotais = parseFloat(row.excHorasTotais).toFixed(2)
      this.contrato.excHorasGastas = parseFloat(row.excHorasGastas).toFixed(2)
      this.contrato.excIncidenciasTotais = row.excIncidenciasTotais
      this.contrato.excIncidenciasGastas = row.excIncidenciasGastas
      this.valorDisponivel.horasDisponiveis = parseFloat(this.contrato.horasTotais - this.contrato.horasGastas).toFixed(2)
      this.valorDisponivel.excHorasDisponiveis = parseFloat(this.contrato.excHorasTotais - this.contrato.excHorasGastas).toFixed(2)
      this.valorDisponivel.incidenciasDisponiveis = this.contrato.incidenciasTotais - this.contrato.incidenciasGastas
      this.valorDisponivel.excIncidenciasDisponiveis = this.contrato.excIncidenciasTotais - this.contrato.excIncidenciasGastas
      this.cliente.id = row.entidade
      this.cliente.cliente = row.codigoEntidade
      this.cliente.nome = row.nomeEntidade

      this.documentosContrato.codigo = row.codigoDocumentoContrato
      this.documentosContrato.descricao = row.descricaoDocumentoContrato

      this.vendedor.id = row.vendedor
      this.vendedor.codigo = row.codigoVendedor
      this.vendedor.nome = row.nomeVendedor

      this.moeda.id = row.moeda
      this.moeda.codigo = row.codigoMoeda
      this.moeda.descricao = row.descricaoMoeda

      this.estadoSelecionado.estado = row.descricaoEstado
      axios.get(`/api/v1/seriesContrato/${row.serie}`)
        .then(res => {
          const resultado = res.data
          this.seriesContrato.series.push(JSON.parse(JSON.stringify(resultado)))
          this.serieSelecionada.serie = res.data
        })
        .catch(error => {
          // Lide com erros aqui, se necessário
        })
      this.hideModalContratos()
      this.$forceUpdate()
    },
    resetForm() {
      this.estadoSelecionado.estado = ''
      this.estadoSelecionado.key = ''
      // this.serieSelecionada.serie = []
      // this.seriesContrato.series = []
      this.contrato.id = ''
      // this.contrato.tipoDoc = ''
      // this.contrato.serie = ''
      // this.contrato.numero = 0
      this.contrato.codigo = ''
      this.contrato.data = null
      this.contrato.entidade = ''
      this.contrato.referencia = ''
      this.contrato.estado = ''
      this.contrato.cancelado = false
      this.contrato.canceladoMotivo = ''
      this.contrato.periodoInicio = null
      this.contrato.periodoFim = null
      this.contrato.renovavel = false
      this.contrato.moeda = ''
      this.contrato.valorContrato = 0
      this.contrato.utilizador = ''
      this.contrato.vendedor = ''
      this.contrato.precoBase = 0
      this.contrato.descricao = ''
      this.contrato.dataAdjudicacao = null
      this.contrato.periodoValidade = false
      this.contrato.horasValidade = false
      this.contrato.horasTotais = '0.00'
      this.contrato.horasGastas = '0.00'
      this.contrato.incidenciasValidade = false
      this.contrato.incidenciasTotais = 0
      this.contrato.incidenciasGastas = 0
      this.contrato.excPeriodoValidade = false
      this.contrato.excPeriodoInicio = null
      this.contrato.excPeriodoFim = null
      this.contrato.excHorasValidade = false
      this.contrato.excHorasTotais = '0.00'
      this.contrato.excHorasGastas = '0.00'
      this.contrato.excIncidenciasValidade = false
      this.contrato.excIncidenciasTotais = 0
      this.contrato.excIncidenciasGastas = 0
      this.contrato.processaFaturacao = false

      this.cliente.id = ''
      this.cliente.cliente = ''
      this.cliente.nome = ''
      this.cliente.nomeFiscal = ''
      this.cliente.morada = ''
      this.cliente.localidade = ''
      this.cliente.codigoPostal = ''
      this.cliente.nif = ''
      this.cliente.idPais = ''
      this.cliente.idMoeda = ''
      this.cliente.idZona = ''
      this.cliente.idVendedor = ''
      this.cliente.telefone = ''
      this.cliente.telemovel = ''
      this.cliente.email = ''
      this.cliente.emailServicos = ''
      this.cliente.ativo = ''
      this.cliente.bloqueado = ''
      this.cliente.observacoes = ''

      this.vendedor.id = ''
      this.vendedor.codigo = ''
      this.vendedor.nome = ''
      this.vendedor.email = ''
      this.vendedor.telemovel = ''

      this.moeda.id = ''
      this.moeda.codigo = ''
      this.moeda.descricao = ''
    },
    alterarNumero() {
      this.resetForm()
      axios.get(`/api/v1/seriesContrato/${this.serieSelecionada.serie.id}/ultimoNumero`)
        .then(res => {
          this.contrato.numero = res.data.numerador + 1
        })
    },
    showModalDocumentosContrato() {
      this.$refs.modalDocumentosContrato.show()
    },
    hideModalDocumentosContrato() {
      this.$refs.modalDocumentosContrato.hide()
    },
    showModalContratos() {
      this.$refs.modalContratos.show()
    },
    hideModalContratos() {
      this.$refs.modalContratos.hide()
    },

    getOneContrato() {
      axios.get(`/api/v1/contratos/tipodoc/${this.contrato.tipoDoc}/serie/${this.contrato.serie}/numdoc/${this.contrato.numero}/complete`)
        .then(res => {
          this.selectContratos(res.data)
        })
    },
  },
}
</script>
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .textoCentrado {
    text-align: center;
  }
  </style>
