var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-form',{on:{"submit":function($event){$event.preventDefault();},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('validation-observer',{ref:"form"},[_c('b-row',[_c('b-col',{attrs:{"md":"3","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Documento","label-for":"tipoDoc"}},[_c('validation-provider',{attrs:{"name":"Documento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"tipoDoc","type":"text","maxlength":"15"},model:{value:(_vm.documentosContrato.descricao),callback:function ($$v) {_vm.$set(_vm.documentosContrato, "descricao", $$v)},expression:"documentosContrato.descricao"}}),_c('b-input-group-prepend',[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.showModalDocumentosContrato}},[_c('feather-icon',{attrs:{"icon":"SearchIcon"}})],1)],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Série","label-for":"h-serie"}},[_c('validation-provider',{attrs:{"name":"Número","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.seriesContrato.series,"label":"serie","title":"serie","reduce":function (serieSelecionada) { return serieSelecionada; },"required":""},on:{"input":function($event){return _vm.alterarNumero(_vm.serieSelecionada.serie)}},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('span',[_vm._v(_vm._s(option.serie)+" - "+_vm._s(option.descricao))])]}}],null,true),model:{value:(_vm.serieSelecionada.serie),callback:function ($$v) {_vm.$set(_vm.serieSelecionada, "serie", $$v)},expression:"serieSelecionada.serie"}},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{staticClass:"align-text-right",attrs:{"label":"Número","label-for":"h-numero"}},[_c('validation-provider',{attrs:{"name":"Número","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon",attrs:{"variant":"outline-secondary"},on:{"click":function($event){_vm.subtraiNumerador(_vm.contrato.numero), _vm.getOneContrato()}}},[_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon"}})],1)],1),_c('b-form-input',{staticClass:"textoCentrado",attrs:{"id":"numero","type":"number","maxlength":"15"},model:{value:(_vm.contrato.numero),callback:function ($$v) {_vm.$set(_vm.contrato, "numero", $$v)},expression:"contrato.numero"}}),_c('b-input-group-prepend',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon",attrs:{"variant":"outline-secondary"},on:{"click":function($event){_vm.somaNumerador(_vm.contrato.numero), _vm.getOneContrato()}}},[_c('feather-icon',{attrs:{"icon":"ChevronRightIcon"}})],1),_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.showModalContratos}},[_c('feather-icon',{attrs:{"icon":"SearchIcon"}})],1)],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Referência","label-for":"h-referencia"}},[_c('div',[_c('h4',[(_vm.documentosContrato.codigo !='' && _vm.contrato.numero != '' && _vm.serieSelecionada.serie != '')?_c('span',[_vm._v(_vm._s(_vm.documentosContrato.codigo)+"/"+_vm._s(_vm.serieSelecionada.serie.serie)+"/"+_vm._s(_vm.contrato.numero))]):_c('span',[_vm._v("#")])])])])],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"3","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Entidade","label-for":"tipoDoc"}},[_c('validation-provider',{attrs:{"name":"Entidade","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"entidade","type":"text","maxlength":"15"},model:{value:(_vm.cliente.cliente),callback:function ($$v) {_vm.$set(_vm.cliente, "cliente", $$v)},expression:"cliente.cliente"}}),_c('b-input-group-prepend',[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.showModalClientes}},[_c('feather-icon',{attrs:{"icon":"SearchIcon"}})],1)],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"9"}},[_c('b-form-group',{attrs:{"label":"Nome","label-for":"h-nome"}},[_c('div',[_c('h4',[_c('h4',[(_vm.cliente.nome != '')?_c('span',[_vm._v(_vm._s(_vm.cliente.nome))]):_c('span',[_vm._v("Selecionar Cliente")])])])])])],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Descrição","label-for":"h-descricao"}},[_c('validation-provider',{attrs:{"name":"Descrição","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-descricao","placeholder":"Descrição","maxlength":"250"},model:{value:(_vm.contrato.descricao),callback:function ($$v) {_vm.$set(_vm.contrato, "descricao", $$v)},expression:"contrato.descricao"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('b-modal',{ref:"modalDocumentosContrato",attrs:{"hide-footer":"","size":"xl"}},[_c('selecionarDocumentoContrato',{on:{"documentosContratoSelecionado":_vm.selectDocumentosContrato}})],1),_c('b-modal',{ref:"modalClientes",attrs:{"hide-footer":"","size":"xl"}},[_c('selecionarCliente',{on:{"clienteSelecionado":_vm.selectCliente}})],1),_c('b-modal',{ref:"modalContratos",attrs:{"hide-footer":"","size":"xl"}},[_c('selecionarContrato',{on:{"contratoSelecionado":_vm.selectContratos}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }