<template>
  <b-card>
    <b-form @submit.prevent>
      <validation-observer ref="form">
        <b-row>
          <h6 class="section-label mx-1 mt-2">
            Validade
          </h6>
        </b-row>
        <b-row>
          <b-col
            md="4"
            class="mt-1 mb-2"
          >
            <b-form-checkbox
              id="horasValidade"
              v-model="contrato.horasValidade"
              checked="contrato.horasValidade"
              name="horasValidade"
              switch
              inline
              :value="true"
            >Validade em Horas
            </b-form-checkbox>
          </b-col>
          <b-col
            v-if="contrato.horasValidade === true"
            md="2"
          >
            <b-form-group
              label="Horas Totais"
              label-for="h-horasTotais"
            >
              <validation-provider
                #default="{ errors }"
                name="Horas Totais"
                rules="required"
              >
                <b-form-input
                  id="h-horasTotais"
                  v-model="contrato.horasTotais"
                  placeholder="Horas Totais"
                  maxlength="15"
                  @change="calculaHoras()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="contrato.horasValidade === true"
            md="2"
          >
            <b-form-group
              label="Horas Gastas"
              label-for="h-horasGastas"
            >
              <b-form-input
                id="h-horasGastas"
                v-model="contrato.horasGastas"
                placeholder="Horas Gastas"
                maxlength="15"
                readonly
              />

            </b-form-group>
          </b-col>
          <b-col
            v-if="contrato.horasValidade === true"
            md="2"
          >
            <b-form-group
              label="Horas Disponíveis"
              label-for="h-horasdisponiveis"
            >
              <b-form-input
                id="h-horasdisponiveis"
                v-model="valorDisponivel.horasDisponiveis"
                placeholder="Horas Disponíveis"
                maxlength="15"
                readonly
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="4"
            class="mb-2"
          >
            <b-form-checkbox
              id="periodoValidade"
              v-model="contrato.periodoValidade"
              checked="contrato.periodoValidade"
              name="periodoValidade"
              switch
              inline
              :value="true"
            >
              <span>Validade Período</span>
            </b-form-checkbox>
          </b-col>
          <b-col
            v-if="contrato.periodoValidade === true"
            md="3"
          >
            <b-form-group
              label="Periodo Inicio"
              label-for="h-periodoInicio"
            >
              <validation-provider
                #default="{ errors }"
                name="Periodo Inicio"
                rules="required"
              >
                <flat-pickr
                  v-model="contrato.periodoInicio"
                  class="form-control"
                  :config="config"
                  placeholder="YYYY-MM-DD"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="contrato.periodoValidade === true"
            md="3"
          >
            <b-form-group
              label="Periodo Fim"
              label-for="h-periodoFim"
            >
              <validation-provider
                #default="{ errors }"
                name="Periodo Fim"
                rules="required"
              >
                <flat-pickr
                  v-model="contrato.periodoFim"
                  class="form-control"
                  :config="config"
                  placeholder="YYYY-MM-DD"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-2"
          >
            <b-form-checkbox
              id="incidenciasValidade"
              v-model="contrato.incidenciasValidade"
              checked="contrato.incidenciasValidade"
              name="incidenciasValidade"
              switch
              inline
              :value="true"
            >Validade em Incidencias
            </b-form-checkbox>
          </b-col>
          <b-col
            v-if="contrato.incidenciasValidade === true"
            cols="12"
            md="2"
          >
            <b-form-group
              label="Incidencias Totais"
              label-for="h-incidenciasTotais"
            >
              <validation-provider
                #default="{ errors }"
                name="Incidencias Totais"
                rules="required"
              >
                <b-form-input
                  id="h-incidenciasTotais"
                  v-model="contrato.incidenciasTotais"
                  placeholder="Incidencias Totais"
                  maxlength="15"
                  @change="calculaIndidencias()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="contrato.incidenciasValidade === true"
            cols="12"
            md="2"
          >
            <b-form-group
              label="Incidencias Gastas"
              label-for="h-incidenciasGastas"
            >
              <b-form-input
                id="h-incidenciasGastas"
                v-model="contrato.incidenciasGastas"
                placeholder="Incidencias Gastas"
                maxlength="15"
                readonly
              />

            </b-form-group>
          </b-col>
          <b-col
            v-if="contrato.incidenciasValidade === true"
            cols="12"
            md="2"
          >
            <b-form-group
              label="Incidencias Disponíveis"
              label-for="h-incidenciasDisponiveis"
            >
              <b-form-input
                id="h-incidenciasDisponiveis"
                v-model="valorDisponivel.incidenciasDisponiveis"
                placeholder="Incidencias Disponíveis"
                maxlength="15"
                readonly
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <h6 class="section-label mx-1 mt-2">
            Exclusivo
          </h6>
        </b-row>
        <b-row>
          <b-col
            md="4"
            class="mt-1 mb-2"
          >
            <b-form-checkbox
              id="excHorasValidade"
              v-model="contrato.excHorasValidade"
              checked="contrato.excHorasValidade"
              name="excHorasValidade"
              switch
              inline
              :value="true"
            >Exclusivo Validade em Horas
            </b-form-checkbox>
          </b-col>
          <b-col
            v-if="contrato.excHorasValidade === true"
            md="2"
          >
            <b-form-group
              label="Horas Totais"
              label-for="h-excHorasTotais"
            >
              <validation-provider
                #default="{ errors }"
                name="Horas Totais"
                rules="required"
              >
                <b-form-input
                  id="h-excHorasTotais"
                  v-model="contrato.excHorasTotais"
                  placeholder="Horas Totais"
                  maxlength="15"
                  @change="CalculaExcHoras()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="contrato.excHorasValidade === true"
            md="2"
          >
            <b-form-group
              label="Horas Gastas"
              label-for="h-excHorasGastas"
            >
              <b-form-input
                id="h-excHorasGastas"
                v-model="contrato.excHorasGastas"
                placeholder="Horas Gastas"
                maxlength="15"
                readonly
              />

            </b-form-group>
          </b-col>
          <b-col
            v-if="contrato.excHorasValidade === true"
            md="2"
          >
            <b-form-group
              label="Horas Disponíveis"
              label-for="h-excHorasdisponiveis"
            >
              <b-form-input
                id="h-excHorasdisponiveis"
                v-model="valorDisponivel.excHorasDisponiveis"
                placeholder="0.00"
                maxlength="15"
                readonly
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="4"
            class="mb-2"
          >
            <b-form-checkbox
              id="excPeriodoValidade"
              v-model="contrato.excPeriodoValidade"
              checked="contrato.excPeriodoValidade"
              name="excPeriodoValidade"
              switch
              inline
              :value="true"
            >
              <span>Exclusivo Validade Período</span>
            </b-form-checkbox>
          </b-col>
          <b-col
            v-if="contrato.excPeriodoValidade === true"
            md="3"
          >
            <b-form-group
              label="Periodo Inicio"
              label-for="h-excPeriodoInicio"
            >
              <validation-provider
                #default="{ errors }"
                name="Periodo Inicio"
                rules="required"
              >
                <flat-pickr
                  v-model="contrato.excPeriodoInicio"
                  class="form-control"
                  :config="config"
                  placeholder="YYYY-MM-DD"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="contrato.excPeriodoValidade === true"
            md="3"
          >
            <b-form-group
              label="Periodo Fim"
              label-for="h-excPeriodoFim"
            >
              <validation-provider
                #default="{ errors }"
                name="Periodo Fim"
                rules="required"
              >
                <flat-pickr
                  v-model="contrato.excPeriodoFim"
                  class="form-control"
                  :config="config"
                  placeholder="YYYY-MM-DD"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-2"
          >
            <b-form-checkbox
              id="excIncidenciasValidade"
              v-model="contrato.excIncidenciasValidade"
              checked="contrato.excIncidenciasValidade"
              name="excIncidenciasValidade"
              switch
              inline
              :value="true"
            >Exclusivo Validade em Incidencias
            </b-form-checkbox>
          </b-col>
          <b-col
            v-if="contrato.excIncidenciasValidade === true"
            cols="12"
            md="2"
          >
            <b-form-group
              label="Incidencias Totais"
              label-for="h-excIncidenciasTotais"
            >
              <validation-provider
                #default="{ errors }"
                name="Incidencias Totais"
                rules="required"
              >
                <b-form-input
                  id="h-excIncidenciasTotais"
                  v-model="contrato.excIncidenciasTotais"
                  placeholder="Incidencias Totais"
                  maxlength="15"
                  @change="calculaExcIndidencias()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="contrato.excIncidenciasValidade === true"
            cols="12"
            md="2"
          >
            <b-form-group
              label="Incidencias Gastas"
              label-for="h-excIncidenciasGastas"
            >
              <b-form-input
                id="h-excIncidenciasGastas"
                v-model="contrato.excIncidenciasGastas"
                placeholder="Incidencias Gastas"
                maxlength="15"
                readonly
              />

            </b-form-group>
          </b-col>
          <b-col
            v-if="contrato.excIncidenciasValidade === true"
            cols="12"
            md="2"
          >
            <b-form-group
              label="Incidencias Disponíveis"
              label-for="h-excIncidenciasDisponiveis"
            >
              <b-form-input
                id="h-excIncidenciasDisponiveis"
                v-model="valorDisponivel.excIncidenciasDisponiveis"
                placeholder="Incidencias Disponíveis"
                maxlength="15"
                readonly
              />
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BInputGroupPrepend,
  BInputGroup, BFormSelect,
  BFormCheckbox,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import pt from 'vee-validate/dist/locale/pt_PT'
import vSelect from 'vue-select'
import { Portuguese } from 'flatpickr/dist/l10n/pt'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BInputGroupPrepend,
    BInputGroup,
    BFormSelect,
    BFormCheckbox,
    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  props: {
    contrato: Object,
    valorDisponivel: Object,
  },
  data() {
    return {

      required,
      config: {
        enableTime: false,
        altFormat: 'd-m-Y',
        altInput: true,
        dateFormat: 'Y-m-dTH:i:00',
        locale: Portuguese, // locale for this instance only
      },
    }
  },
  created() {
    localize('pt', pt)
  },

  methods: {

    numberFormat(value) {
      return value.replace(',', '.')
    },
    calculaHoras() {
      this.contrato.horasTotais = this.contrato.horasTotais.replace(',', '.')
      this.contrato.horasGastas = this.contrato.horasGastas.replace(',', '.')
      this.valorDisponivel.horasDisponiveis = parseFloat(this.contrato.horasTotais - this.contrato.horasGastas).toFixed(2)
    },
    CalculaExcHoras() {
      this.contrato.excHorasTotais = this.contrato.excHorasTotais.replace(',', '.')
      this.contrato.excHorasGastas = this.contrato.excHorasGastas.replace(',', '.')
      this.valorDisponivel.excHorasDisponiveis = parseFloat(this.contrato.excHorasTotais - this.contrato.excHorasGastas).toFixed(2)
    },
    calculaIndidencias() {
      this.valorDisponivel.incidenciasDisponiveis = this.contrato.incidenciasTotais - this.contrato.incidenciasGastas
    },
    calculaExcIndidencias() {
      this.valorDisponivel.excIncidenciasDisponiveis = this.contrato.excIncidenciasTotais - this.contrato.excIncidenciasGastas
    },
  },
}
</script>
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
